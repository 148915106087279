.background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: radial-gradient(circle at center,
    rgba(20, 20, 40, 0.95) 0%,
    rgba(10, 10, 20, 0.98) 50%,
    rgba(0, 0, 0, 1) 100%
  );
}

.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  
  &.fallback {
    background: linear-gradient(135deg, 
      rgba(0, 0, 0, 0.95) 0%,
      rgba(20, 20, 40, 0.95) 100%
    );
  }
}
