@font-face {
  font-family: "Lora";
  font-weight: normal;
  font-style: normal;

  src: url("../assets/fonts/Lora-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Lora";
  font-weight: bold;
  font-style: normal;

  src: url("../assets/fonts/Lora-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Lora";
  font-weight: 600;
  font-style: normal;

  src: url("../assets/fonts/Lora-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Lora";
  font-weight: 500;
  font-style: normal;

  src: url("../assets/fonts/Lora-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Geostar Fill";
  font-weight: normal;
  font-style: normal;

  src: url("../assets/fonts/GeostarFill-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Geostar Fill";
  font-weight: 400;
  font-style: normal;

  src: url("../assets/fonts/GeostarFill-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Abril Fatface";
  font-weight: normal;
  font-style: normal;

  src: url("../assets/fonts/AbrilFatface-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto";
  font-weight: normal;
  font-style: normal;

  src: url("../assets/fonts/Roboto-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto";
  font-weight: 500;
  font-style: normal;

  src: url("../assets/fonts/Roboto-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto";
  font-weight: Bold;
  font-style: normal;

  src: url("../assets/fonts/Roboto-Bold.ttf") format("truetype");
}
