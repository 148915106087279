.base {
  display: flex;
  justify-content: center;
  margin-bottom: 200px;
  .circ {
    width: 10rem;
    height: 10rem;
    border-radius: 50%;
    position: absolute;
    transform-style: preserve-3d;
    background: rgba(0, 0, 0, 0.5);
    border: 1.9px solid;
    border-top-color: rgba(220, 128, 109, 0.5);
    border-bottom-color: rgba(151, 93, 193, 0.5);
    border-left-color: rgba(151, 93, 193, 0.5);
    border-right-color: rgba(220, 128, 109, 0.5);
    opacity: 0;
    @for $i from 1 through 7 {
      &.circ#{$i} {
        transform: rotate3d(0, 1, 0, (calc(360deg / 15)) * $i);
        animation: rotate 2s linear infinite;
        animation-delay: calc(1 / 7.5) * $i + s;
      }
    }
    @for $i from 8 through 15 {
      &.circ#{$i} {
        transform: rotate3d(1, 0, 0, (calc(360deg / 15)) * $i);
        animation: rotate-2 3s linear infinite;
        animation-delay: calc(1 / 7.5) * $i + s;
      }
    }
  }
}

@keyframes rotate {
  from {
    opacity: 1;
    transform: rotate3d(0, 1, 1, 360deg);
  }
  to {
    transform: rotate3d(0, 1, 1, 0deg);
    opacity: 1;
  }
}
@keyframes rotate-2 {
  from {
    opacity: 1;
    transform: rotate3d(1, 0, 1, 0deg);
  }
  to {
    opacity: 1;
    transform: rotate3d(1, 0, 1, 360deg);
  }
}
