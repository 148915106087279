@import "../../styles/vars.scss";
.paragraphContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
  @media screen and (max-width: $mobileAlb) {
    gap: 20px;
  }
  .paragraph {
    font-size: 24px;
    font-weight: 400;
    width: 100%;

    div {
      width: 100%;
    }
    @media screen and (max-width: $mobile) {
      font-size: 13px;
    }
  }

  .bold {
    font-weight: bold;
  }
}
