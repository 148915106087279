@import "../../styles/vars.scss";

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
  min-height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  @media screen and (max-width: $mobile) {
    padding: 20px;
  }
}

.title {
  display: block;
  font-family: 'Space Mono', monospace;
  font-size: 200px;
  font-weight: 500;
  color: #000;
  text-align: center;
  margin: -26px 0 82px;
  text-decoration: none;
  
  &:hover {
    text-decoration: underline;
  }
  
  @media screen and (max-width: $tabletAlb) {
    font-size: 160px;
    margin: -22.5px 0 36.5px;
  }

  @media screen and (max-width: $tablet) {
    font-size: 110px;
    margin-top: -15.5px;
  }

  @media screen and (max-width: $mobileAlb) {
    font-size: 70px;
    margin: -9.5px 0 28.5px;
  }

  @media screen and (max-width: $mobile) {
    font-size: 50px;
    margin: -7px 0 8px;
  }
}

.content {
  opacity: 0;
  animation: fadeIn 0.8s cubic-bezier(0.16, 1, 0.3, 1) forwards;
  animation-delay: 0.2s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.center {
  text-align: center;
}
