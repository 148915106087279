@import "../../styles/vars.scss";

.title {
  font-family: 'Space Mono', monospace;
  font-size: 82px;
  font-weight: 700;
  letter-spacing: -2px;
  color: white;
  text-align: center;
  margin-bottom: 80px;
  position: relative;
  opacity: 0;
  animation: fadeIn 0.8s cubic-bezier(0.16, 1, 0.3, 1) forwards;
  
  &::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 60%;
    height: 1px;
    background: linear-gradient(90deg,
      transparent,
      rgba(255, 255, 255, 0.2) 20%,
      rgba(255, 255, 255, 0.2) 80%,
      transparent
    );
  }

  @media screen and (max-width: $tablet) {
    font-size: 64px;
    margin-bottom: 60px;
  }

  @media screen and (max-width: $mobile) {
    font-size: 48px;
    margin-bottom: 40px;
  }
}

.sectionsContainer {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0 20px;
  opacity: 0;
  animation: fadeIn 0.8s cubic-bezier(0.16, 1, 0.3, 1) forwards;
  animation-delay: 0.2s;
  margin-bottom: 80px;

  @media screen and (max-width: $tablet) {
    gap: 15px;
    margin-bottom: 60px;
  }

  @media screen and (max-width: $mobile) {
    gap: 10px;
    padding: 0 15px;
    margin-bottom: 40px;
  }
}

.section {
  background: rgba(255, 255, 255, 0.03);
  border-radius: 12px;
  padding: 30px;
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);
  
  &:hover {
    background: rgba(255, 255, 255, 0.05);
    transform: translateY(-2px);
    
    .sectionLink {
      color: white;
    }
    
    .sectionArrow {
      transform: translateX(5px);
      opacity: 1;
    }
  }

  @media screen and (max-width: $tablet) {
    padding: 25px;
  }

  @media screen and (max-width: $mobile) {
    padding: 20px;
  }
}

.sectionItem {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 15px;

  @media screen and (max-width: $mobile) {
    gap: 15px;
    margin-bottom: 10px;
  }
}

.sectionNumber {
  font-family: 'Space Mono', monospace;
  font-size: 18px;
  color: rgba(255, 255, 255, 0.6);
  min-width: 40px;
  text-align: center;
  
  @media screen and (max-width: $mobile) {
    font-size: 14px;
    min-width: 30px;
  }
}

.sectionLink {
  font-family: 'Space Mono', monospace;
  font-size: 24px;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.9);
  flex-grow: 1;
  transition: color 0.3s ease;
  
  @media screen and (max-width: $tablet) {
    font-size: 20px;
  }
  
  @media screen and (max-width: $mobile) {
    font-size: 16px;
  }
}

.sectionArrow {
  font-family: 'Space Mono', monospace;
  font-size: 24px;
  color: rgba(255, 255, 255, 0.6);
  transition: all 0.3s ease;
  opacity: 0.6;
  
  @media screen and (max-width: $mobile) {
    font-size: 18px;
  }
}

.sectionDescription {
  font-family: 'Space Mono', monospace;
  font-size: 16px;
  line-height: 1.6;
  color: rgba(255, 255, 255, 0.7);
  padding-left: 60px;
  
  @media screen and (max-width: $tablet) {
    font-size: 14px;
    padding-left: 45px;
  }
  
  @media screen and (max-width: $mobile) {
    font-size: 12px;
    padding-left: 30px;
  }
}

.footer {
  background: rgba(255, 255, 255, 0.03);
  border-radius: 12px;
  padding: 30px;
  margin-top: 80px;
  text-align: center;
  
  @media screen and (max-width: $tablet) {
    padding: 25px;
    margin-top: 60px;
  }
  
  @media screen and (max-width: $mobile) {
    padding: 20px;
    margin-top: 40px;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
