@import "../../styles/vars.scss";

.toggle {
  width: 70px;
  height: 32px;
  border-radius: 100px;
  border: 2px solid #938f99;
  background-color: #36343b;
  cursor: pointer;
  position: relative;
  transition: 0.4s;
  @media screen and (max-width: $mobile) {
    width: 50px;
    height: 50px;
    border-radius: 0;
    border: none;
    background-color: inherit;
    background-image: url("data:image/svg+xml,%3Csvg width='48' height='49' viewBox='0 0 48 49' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_709_1977)'%3E%3Crect width='40' height='40' transform='translate(4 4.28125)' fill='%23D0BCFF' fill-opacity='0.12'/%3E%3Cpath d='M24 14.2812C18.48 14.2812 14 18.7613 14 24.2812C14 29.8013 18.48 34.2812 24 34.2812C29.52 34.2812 34 29.8013 34 24.2812C34 18.7613 29.52 14.2812 24 14.2812ZM24 32.2812C19.58 32.2812 16 28.7013 16 24.2812C16 19.8612 19.58 16.2812 24 16.2812C28.42 16.2812 32 19.8612 32 24.2812C32 28.7013 28.42 32.2812 24 32.2812Z' fill='%23E6E0E9'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_709_1977'%3E%3Crect x='4' y='4.28125' width='40' height='40' rx='20' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
  }
  &::before {
    position: absolute;
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='49' height='48' viewBox='0 0 49 48' fill='none'%3E%3Crect x='4.5' y='4' width='40' height='40' rx='20' fill='%23E6E0E9' fill-opacity='0.12'/%3E%3Crect x='10.5' y='10' width='28' height='28' rx='14' fill='%23CAC4D0'/%3E%3C/svg%3E");
    top: -10px;
    left: -11px;
    transition: 0.4s;
    @media screen and (max-width: $mobile) {
      display: none;
    }
  }
}
.toggleInput:checked + .toggle {
  background-color: #6750a4;
  border: 2px solid #6750a4;
  @media screen and (max-width: $mobile) {
    border: none;
    background-color: inherit;
    background-image: url("data:image/svg+xml,%3Csvg width='48' height='49' viewBox='0 0 48 49' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_709_1968)'%3E%3Crect width='40' height='40' transform='translate(4 4.28125)' fill='%23E6E0E9' fill-opacity='0.12'/%3E%3Cpath d='M24 14.2812C18.48 14.2812 14 18.7613 14 24.2812C14 29.8012 18.48 34.2812 24 34.2812C29.52 34.2812 34 29.8012 34 24.2812C34 18.7613 29.52 14.2812 24 14.2812ZM24 32.2812C19.58 32.2812 16 28.7013 16 24.2812C16 19.8612 19.58 16.2812 24 16.2812C28.42 16.2812 32 19.8612 32 24.2812C32 28.7013 28.42 32.2812 24 32.2812Z' fill='%23D0BCFF'/%3E%3Cpath d='M24 29.2812C26.7614 29.2812 29 27.0427 29 24.2812C29 21.5198 26.7614 19.2812 24 19.2812C21.2386 19.2812 19 21.5198 19 24.2812C19 27.0427 21.2386 29.2812 24 29.2812Z' fill='%23D0BCFF'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_709_1968'%3E%3Crect x='4' y='4.28125' width='40' height='40' rx='20' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
  }
}

.toggleInput:checked + .toggle::before {
  transform: translateX(38px);
  @media screen and (max-width: $mobile) {
    transform: none;
  }
}
.toggleInput {
  display: none;
}
