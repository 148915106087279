@import "../../styles/vars.scss";

.footerWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 100;
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2.5rem; 
  padding: 1.25rem; 
  flex-wrap: wrap;
  position: relative;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(0.625rem); 
  -webkit-backdrop-filter: blur(0.625rem);
  color: #fff;
  transition: all 0.3s ease;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    height: 0.0625rem; 
    background: currentColor;
    opacity: 0.1;
  }
  
  &.lightPage {
    color: #000;
    background: rgba(255, 255, 255, 0.2);
  }

  &.hidden {
    transform: translateY(100%);
  }
  
  @media screen and (max-width: $tablet) {
    gap: 1.5625rem; 
    padding: 0.9375rem 1.25rem; 
  }
  
  @media screen and (max-width: $mobile) {
    gap: 1.5 rem; 
    padding: 0.75rem 1rem; 
    background: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(0.5rem); 
    -webkit-backdrop-filter: blur(0.5rem);
    
    &.lightPage {
      background: rgba(255, 255, 255, 0.8);
    }
    
    &::before {
      display: none;
    }
  }
}

.toggleButton {
  display: none;
}

.link {
  font-family: 'Space Mono', monospace;
  text-decoration: none;
  color: currentColor;
  font-size: 0.875rem; 
  letter-spacing: 0.0625rem; 
  transition: opacity 0.2s ease;
  opacity: 0.7;
  text-transform: uppercase;
  white-space: nowrap;
  
  &:hover {
    opacity: 1;
  }
  
  @media screen and (max-width: $mobile) {
    font-size: 0.75rem; 
    letter-spacing: 0.03125rem; 
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
