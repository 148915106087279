@import "../../styles/vars.scss";

.mainContainer {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 20px;
  
  @media screen and (max-width: $mobile) {
    padding: 20px;
  }
  
  .titleContainer {
    text-align: center;
    margin-bottom: 80px;
    opacity: 0;
    animation: fadeIn 0.8s cubic-bezier(0.16, 1, 0.3, 1) forwards;
    animation-delay: 0.2s;
    
    @media screen and (max-width: $mobile) {
      margin-bottom: 40px;
    }
    
    .title {
      font-family: 'Space Mono', monospace;
      font-size: 82px;
      font-weight: 700;
      letter-spacing: -2px;
      margin: 0;
      color: white;
      text-transform: uppercase;
      position: relative;
      
      &::after {
        content: '';
        position: absolute;
        bottom: -10px;
        left: 50%;
        transform: translateX(-50%);
        width: 60%;
        height: 1px;
        background: linear-gradient(90deg,
          transparent,
          rgba(255, 255, 255, 0.2) 20%,
          rgba(255, 255, 255, 0.2) 80%,
          transparent
        );
      }
      
      @media screen and (max-width: $tablet) {
        font-size: 64px;
      }
      
      @media screen and (max-width: $mobile) {
        font-size: 48px;
      }
    }
    
    .subtitle {
      font-family: 'Space Mono', monospace;
      font-size: 18px;
      color: rgba(255, 255, 255, 0.7);
      margin-top: 20px;
      letter-spacing: 2px;
      text-transform: uppercase;
      
      @media screen and (max-width: $mobile) {
        font-size: 14px;
        letter-spacing: 1px;
      }
    }
  }
  
  .navigation {
    width: 100%;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    opacity: 0;
    animation: fadeIn 0.5s ease forwards;
    animation-delay: 0.2s;
    margin: 0 auto;
    
    @media screen and (max-width: $mobile) {
      gap: 15px;
    }
    
    .menuItem {
      position: relative;
      transition: transform 0.2s ease;
      
      @media screen and (max-width: $mobile) {
        transition: none;
        
        &.active {
          transform: none;
          
          .menuLink {
            background: rgba(255, 255, 255, 0.08);
            
            .menuTitle {
              transform: none;
            }
          }
        }
      }
      
      &.active {
        transform: translateX(20px);
        
        .menuLink {
          background: rgba(255, 255, 255, 0.1);
          border-color: rgba(255, 255, 255, 0.2);
          
          .menuTitle {
            color: white;
            transform: translateX(10px);
          }
          
          .menuDescription {
            opacity: 1;
            transform: translateY(0);
          }
        }
      }
      
      .menuLink {
        display: block;
        padding: 30px;
        text-decoration: none;
        background: rgba(255, 255, 255, 0.05);
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 4px;
        transition: all 0.2s ease;
        
        @media screen and (max-width: $mobile) {
          padding: 20px;
          transition: background-color 0.2s ease;
          will-change: background-color;
          
          &:hover {
            transform: none;
          }
        }
        
        &:hover {
          background: rgba(255, 255, 255, 0.08);
          border-color: rgba(255, 255, 255, 0.15);
          transform: translateY(-2px);
        }
        
        .menuTitle {
          display: block;
          font-family: 'Space Mono', monospace;
          font-size: 28px;
          font-weight: 700;
          color: rgba(255, 255, 255, 0.8);
          margin-bottom: 12px;
          transition: all 0.2s ease;
          letter-spacing: 1px;
          
          @media screen and (max-width: $mobile) {
            font-size: 24px;
          }
        }
        
        .menuDescription {
          font-size: 16px;
          color: rgba(255, 255, 255, 0.6);
          line-height: 1.6;
          opacity: 0.8;
          transition: opacity 0.2s ease;
          
          @media screen and (max-width: $mobile) {
            font-size: 14px;
            line-height: 1.4;
            opacity: 1;
          }
        }
      }
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
