* {
  cursor: none !important;
}

.cursorOuter,
.cursorInner {
  pointer-events: none;
  position: fixed;
  border-radius: 50%;
  z-index: 9999;
  mix-blend-mode: difference;
  will-change: transform;
}

.cursorOuter {
  width: 32px;
  height: 32px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  transition: width 0.4s cubic-bezier(0.16, 1, 0.3, 1),
              height 0.4s cubic-bezier(0.16, 1, 0.3, 1),
              background-color 0.4s cubic-bezier(0.16, 1, 0.3, 1);

  &.hover {
    width: 48px;
    height: 48px;
    background-color: rgba(255, 255, 255, 0.1);
  }
}

.cursorInner {
  width: 4px;
  height: 4px;
  background-color: #fff;
  opacity: 0.8;
  transition: width 0.4s cubic-bezier(0.16, 1, 0.3, 1),
              height 0.4s cubic-bezier(0.16, 1, 0.3, 1),
              opacity 0.4s cubic-bezier(0.16, 1, 0.3, 1);

  &.hover {
    width: 8px;
    height: 8px;
    opacity: 1;
  }
}

@media (max-width: 768px) {
  * {
    cursor: auto !important;
  }
  
  .cursorOuter,
  .cursorInner {
    display: none;
  }
}
