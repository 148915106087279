@import "../../styles/vars.scss";

.wrapper {
  width: 100vw;
  min-height: 100vh;
  overflow-x: hidden;
  background-size: cover;
  background-color: #000;
  display: flex;
  flex-direction: column;
  position: relative;

  .content {
    flex: 1;
    max-width: 1440px;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding: 0 20px;
    min-height: 100vh;
    padding-bottom: 20px; // Space for footer
    
    .children {
      width: 100%;
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 80px 0 0; // Space for header
      opacity: 0;
      animation: fadeIn 0.8s cubic-bezier(0.16, 1, 0.3, 1) forwards;
      animation-delay: 0.1s;
      
      @media screen and (max-width: $tablet) {
        padding-top: 60px;
      }
      
      @media screen and (max-width: $mobile) {
        padding-top: 50px;
      }
    }
    
    .footer {
      width: 100%;
      display: flex;
      justify-content: center;
      padding: 20px 0;
      background: rgba(0, 0, 0, 0.5);
      position: fixed;
      bottom: 0;
      left: 0;
      z-index: 10;
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
