@import "../../styles/vars.scss";

.header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  padding: 20px 40px;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(8px);
  
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 80px);
    height: 1px;
    background: linear-gradient(90deg,
      transparent,
      rgba(255, 255, 255, 0.1) 20%,
      rgba(255, 255, 255, 0.1) 80%,
      transparent
    );
  }
  
  @media screen and (max-width: $tablet) {
    padding: 15px 30px;
    
    &::after {
      width: calc(100% - 60px);
    }
  }
  
  @media screen and (max-width: $mobile) {
    padding: 12px 20px;
    
    &::after {
      width: calc(100% - 40px);
    }
  }
  
  &.color {
    background: linear-gradient(to bottom,
      rgba(255, 255, 255, 0.95) 0%,
      rgba(255, 255, 255, 0.8) 50%,
      rgba(255, 255, 255, 0) 100%
    );
    
    &::after {
      background: linear-gradient(90deg,
        transparent,
        rgba(0, 0, 0, 0.1) 20%,
        rgba(0, 0, 0, 0.1) 80%,
        transparent
      );
    }
    
    .link {
      color: rgba(0, 0, 0, 0.7);
      
      &:hover {
        color: rgba(0, 0, 0, 0.95);
        background: rgba(0, 0, 0, 0.05);
      }
      
      &::after {
        background: rgba(0, 0, 0, 0.2);
      }
    }
  }
}

.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1440px;
  margin: 0 auto;
}

.link {
  font-family: 'Space Mono', monospace;
  font-size: 14px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.7);
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  padding: 8px 16px;
  border-radius: 4px;
  position: relative;
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);
  opacity: 0;
  transform: translateY(-20px);
  animation: fadeInDown 0.6s cubic-bezier(0.16, 1, 0.3, 1) forwards;
  
  &::after {
    content: '';
    position: absolute;
    bottom: 4px;
    left: 16px;
    width: 0;
    height: 1px;
    background: rgba(255, 255, 255, 0.3);
    transition: width 0.3s cubic-bezier(0.16, 1, 0.3, 1);
  }
  
  &:hover {
    color: rgba(255, 255, 255, 0.95);
    background: rgba(255, 255, 255, 0.05);
    
    &::after {
      width: calc(100% - 32px);
    }
  }
  
  @media screen and (max-width: $mobile) {
    font-size: 12px;
    padding: 6px 12px;
    
    &::after {
      bottom: 3px;
      left: 12px;
    }
    
    &:hover::after {
      width: calc(100% - 24px);
    }
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
