@import "../../styles/vars.scss";

.loading {
  background-color: black;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  animation: fade-out 1s ease forwards;
  
  &::after {
    content: '';
    width: 60px;
    height: 60px;
    border: 2px solid transparent;
    border-top-color: white;
    border-radius: 50%;
    animation: rose-spin 1s ease-in-out infinite, rose-bloom 2s ease-in-out infinite;
    position: relative;
  }

  &::before {
    content: '';
    position: absolute;
    width: 40px;
    height: 40px;
    border: 2px solid transparent;
    border-bottom-color: white;
    border-radius: 50%;
    animation: rose-spin 1.5s ease-in-out infinite reverse, rose-bloom 2s ease-in-out infinite 0.5s;
  }
}

@keyframes rose-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes rose-bloom {
  0%, 100% {
    border-width: 2px;
  }
  50% {
    border-width: 4px;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
