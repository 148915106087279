@import "../../styles/vars.scss";

.searchModule {
  display: flex;
  flex-direction: column;
  gap: 2.5rem; // 40px
  align-items: center;
  max-width: 50rem; // 800px
  margin: 0 auto;
  padding: 1.25rem; // 20px
  
  @media screen and (max-width: $mobileAlb) {
    gap: 1.875rem; // 30px
  }
  
  @media screen and (max-width: $mobile) {
    gap: 1.25rem; // 20px
    padding: 0.9375rem; // 15px
  }
}

.searchToggle {
  display: flex;
  align-items: center;
  gap: 1.875rem; // 30px
  
  @media screen and (max-width: $mobile) {
    gap: 0.9375rem; // 15px
  }
}

.searchVariants {
  font-family: 'Space Mono', monospace;
  font-size: 1.5rem; // 24px
  font-weight: 500;
  color: rgba(255, 255, 255, 0.8);
  text-transform: uppercase;
  letter-spacing: 0.0625rem; // 1px
  
  @media screen and (max-width: $mobileAlb) {
    font-size: 1.25rem; // 20px
  }
  
  @media screen and (max-width: $mobile) {
    font-size: 1rem; // 16px
  }
}

.searchInput {
  width: 100%;
  position: relative;
  
  &::after {
    content: '';
    position: absolute;
    bottom: -0.125rem; // -2px
    left: 0;
    width: 100%;
    height: 0.0625rem; // 1px
    background: linear-gradient(90deg,
      transparent,
      rgba(255, 255, 255, 0.2) 20%,
      rgba(255, 255, 255, 0.2) 80%,
      transparent
    );
  }
  
  input {
    width: 100%;
    background: none;
    border: none;
    outline: none;
    padding: 0.625rem 2.5rem 0.625rem 1.25rem; // 10px 40px 10px 20px
    font-family: 'Space Mono', monospace;
    font-size: 1rem; // 16px
    color: rgba(255, 255, 255, 0.8);
    transition: color 0.3s ease;
    cursor: text;

    &::placeholder {
      color: rgba(255, 255, 255, 0.4);
      transition: color 0.3s ease;
    }

    &:hover {
      &::placeholder {
        color: rgba(255, 255, 255, 0.6);
      }
    }

    &:focus {
      color: rgba(255, 255, 255, 1);
      
      &::placeholder {
        color: rgba(255, 255, 255, 0.6);
      }
    }
  }
}

.searchIcon {
  position: absolute;
  right: 0.9375rem; // 15px
  top: 50%;
  transform: translateY(-50%);
  color: rgba(255, 255, 255, 0.4);
  font-size: 1.125rem; // 18px
  transition: color 0.3s ease;
  cursor: default;
  user-select: none;
}

.searchInput:hover .searchIcon,
.searchInput:focus-within .searchIcon {
  color: rgba(255, 255, 255, 0.6);
}

.sliderModule {
  margin-top: 3.75rem; // 60px
  min-height: 37.5rem; // 600px
  opacity: 0;
  transform: translateY(1.25rem); // 20px
  animation: slideIn 0.6s cubic-bezier(0.16, 1, 0.3, 1) forwards;
  
  @media screen and (max-width: $mobileAlb) {
    margin-top: 2.5rem; // 40px
    min-height: 31.25rem; // 500px
  }
  
  @media screen and (max-width: $mobile) {
    margin-top: 1.875rem; // 30px
    min-height: 25rem; // 400px
  }
}

.errorMessage {
  color: rgba(255, 255, 255, 0.8);
  font-family: 'Space Mono', monospace;
  font-size: 1rem; // 16px
  text-align: center;
  padding: 0.625rem; // 10px
  border-radius: 0.25rem; // 4px
  background: rgba(255, 0, 0, 0.1);
  border: 0.0625rem solid rgba(255, 0, 0, 0.2);
  margin-top: 1.25rem; // 20px
  animation: fadeIn 0.3s ease;
  
  @media screen and (max-width: $mobile) {
    font-size: 0.875rem; // 14px
    padding: 0.5rem; // 8px
  }
}

.resultsContainer {
  width: 100%;
  max-width: 50rem; // 800px
  margin: 2.5rem auto; // 40px
  padding: 0 1.25rem; // 20px
  
  @media screen and (max-width: $mobile) {
    margin: 1.25rem auto; // 20px
    padding: 0 0.9375rem; // 15px
  }
}

.resultsList {
  display: flex;
  flex-direction: column;
  gap: 1.25rem; // 20px
}

.resultItem {
  display: block;
  text-decoration: none;
  padding: 1.25rem; // 20px
  background: rgba(0, 0, 0, 0.7);
  border: 0.0625rem solid rgba(255, 255, 255, 0.1); // 1px
  border-radius: 0.25rem; // 4px
  transition: all 0.3s ease;
  cursor: pointer;
  
  &:hover {
    background: rgba(0, 0, 0, 0.8);
    transform: translateY(-0.125rem); // -2px
    border-color: rgba(255, 255, 255, 0.2);
  }
  
  &:active {
    transform: translateY(0);
  }
}

.resultTitle {
  font-family: 'Space Mono', monospace;
  font-size: 1.25rem; // 20px
  margin: 0 0 0.625rem; // 10px
  color: white;
  
  @media screen and (max-width: $mobile) {
    font-size: 1.125rem; // 18px
  }
}

.resultContent {
  font-family: 'Space Mono', monospace;
  font-size: 1rem; // 16px
  margin: 0;
  line-height: 1.5;
  color: rgba(255, 255, 255, 0.6);
  
  &:empty {
    display: none;
  }
  
  @media screen and (max-width: $mobile) {
    font-size: 0.875rem; // 14px
  }
}

.noResults {
  text-align: center;
  color: rgba(255, 255, 255, 0.8);
  font-family: 'Space Mono', monospace;
  font-size: 1.125rem; // 18px
  padding: 1.25rem; // 20px
  
  @media screen and (max-width: $mobile) {
    font-size: 1rem; // 16px
    padding: 0.9375rem; // 15px
  }
}

.titleContainer {
  text-align: center;
  margin-bottom: 3.75rem; // 60px
  
  a {
    text-decoration: none;
    cursor: pointer;
    
    &:hover .title {
      opacity: 0.8;
    }
  }
  
  .title {
    font-family: 'Space Mono', monospace;
    font-size: 5.125rem; // 82px
    font-weight: 700;
    letter-spacing: -0.125rem; // -2px
    margin: 0;
    color: white;
    text-transform: uppercase;
    position: relative;
    
    &::after {
      content: '';
      position: absolute;
      bottom: -0.625rem; // -10px
      left: 50%;
      transform: translateX(-50%);
      width: 37.5rem; // 600px
      height: 0.0625rem; // 1px
      background: linear-gradient(90deg,
        transparent,
        rgba(255, 255, 255, 0.2) 20%,
        rgba(255, 255, 255, 0.2) 80%,
        transparent
      );
    }
    
    @media screen and (max-width: $tablet) {
      font-size: 4rem; // 64px
    }
    
    @media screen and (max-width: $mobile) {
      font-size: 3rem; // 48px
    }
  }
  
  .subtitle {
    font-family: 'Space Mono', monospace;
    font-size: 1.125rem; // 18px
    color: rgba(255, 255, 255, 0.7);
    margin-top: 1.25rem; // 20px
    letter-spacing: 0.125rem; // 2px
    text-transform: uppercase;
    
    @media screen and (max-width: $mobile) {
      font-size: 0.875rem; // 14px
      letter-spacing: 0.0625rem; // 1px
    }
  }
}

@keyframes slideIn {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}
