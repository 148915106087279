@import "../../styles/vars.scss";

.info {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
  opacity: 0;
  animation: fadeIn 0.8s cubic-bezier(0.16, 1, 0.3, 1) forwards;
  
  @media screen and (max-width: $mobile) {
    padding: 20px;
  }
}

.titleContainer {
  text-align: center;
  margin-bottom: 60px;
  opacity: 0;
  animation: fadeIn 0.8s cubic-bezier(0.16, 1, 0.3, 1) forwards;
  animation-delay: 0.2s;
}

.lfname {
  font-family: 'Space Mono', monospace;
  font-size: 82px;
  font-weight: 700;
  letter-spacing: -2px;
  margin: 0;
  color: white;
  text-transform: uppercase;
  position: relative;
  
  &::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 60%;
    height: 1px;
    background: linear-gradient(90deg,
      transparent,
      rgba(255, 255, 255, 0.2) 20%,
      rgba(255, 255, 255, 0.2) 80%,
      transparent
    );
  }
  
  @media screen and (max-width: $tablet) {
    font-size: 64px;
  }
  
  @media screen and (max-width: $mobile) {
    font-size: 48px;
  }
}

.mname {
  font-family: 'Space Mono', monospace;
  font-size: 32px;
  color: rgba(255, 255, 255, 0.7);
  margin-top: 20px;
  letter-spacing: 2px;
  text-transform: uppercase;
  
  @media screen and (max-width: $mobile) {
    font-size: 24px;
    letter-spacing: 1px;
  }
}

.bio {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  margin: 60px 0;
  opacity: 0;
  animation: fadeIn 0.8s cubic-bezier(0.16, 1, 0.3, 1) forwards;
  animation-delay: 0.4s;
  
  @media screen and (max-width: $tablet) {
    grid-template-columns: 1fr;
    gap: 30px;
    margin: 40px 0;
  }
}

.photo {
  position: relative;
  width: 100%;
  aspect-ratio: 1;
  overflow: hidden;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid rgba(255, 255, 255, 0.1);
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s cubic-bezier(0.16, 1, 0.3, 1);
    
    &:hover {
      transform: scale(1.05);
    }
  }
}

.description {
  font-family: 'Space Mono', monospace;
  font-size: 18px;
  line-height: 1.6;
  color: rgba(255, 255, 255, 0.8);
  
  @media screen and (max-width: $mobile) {
    font-size: 16px;
  }
}

.links {
  opacity: 0;
  animation: fadeIn 0.8s cubic-bezier(0.16, 1, 0.3, 1) forwards;
  animation-delay: 0.6s;
}

.linksTitle {
  font-family: 'Space Mono', monospace;
  font-size: 24px;
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 20px;
  text-transform: uppercase;
  letter-spacing: 1px;
  
  @media screen and (max-width: $mobile) {
    font-size: 20px;
  }
}

.linksContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.link {
  display: block;
  text-decoration: none;
  padding: 20px;
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  color: rgba(255, 255, 255, 0.8);
  font-family: 'Space Mono', monospace;
  font-size: 16px;
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);
  
  &:hover {
    background: rgba(255, 255, 255, 0.1);
    border-color: rgba(255, 255, 255, 0.2);
    transform: translateY(-2px);
    color: white;
  }
  
  @media screen and (max-width: $mobile) {
    font-size: 14px;
    padding: 15px;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
