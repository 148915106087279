@import "../../styles/vars.scss";

.header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-bottom: 1.875rem;
  opacity: 0;
  animation: fadeIn 0.8s cubic-bezier(0.16, 1, 0.3, 1) forwards;

  @media screen and (max-width: $mobile) {
    display: none;
  }

  .headerItem {
    font-family: 'Space Mono', monospace;
    font-size: 1.125rem;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0.0625rem;
    color: rgba(255, 255, 255, 0.8);
    transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);

    &:hover {
      color: white;
      transform: translateY(-0.0625rem);
    }
  }
}

.linetop,
.linebot {
  width: 100%;
  height: 0.0625rem;
  background: linear-gradient(90deg,
    transparent,
    rgba(255, 255, 255, 0.2) 20%,
    rgba(255, 255, 255, 0.2) 80%,
    transparent
  );
  opacity: 0;
  animation: fadeIn 0.8s cubic-bezier(0.16, 1, 0.3, 1) forwards;
  animation-delay: 0.2s;

  @media screen and (max-width: $mobile) {
    opacity: 0.5;
  }
}

.linetop {
  margin-bottom: 3.125rem;

  @media screen and (max-width: $mobile) {
    margin-bottom: 0.75rem;
  }
}

.linebot {
  margin-top: 3.125rem;

  @media screen and (max-width: $mobile) {
    margin-top: 0.75rem;
  }
}

.title {
  width: 100%;
  font-family: 'Space Mono', monospace;
  font-size: 8rem;
  font-weight: 700;
  letter-spacing: -0.125rem;
  display: flex;
  overflow: hidden;
  opacity: 0;
  animation: fadeIn 0.8s cubic-bezier(0.16, 1, 0.3, 1) forwards;
  animation-delay: 0.3s;

  @media screen and (max-width: $mobile) {
    font-size: 1.5rem;
    letter-spacing: 0;
    padding: 0.75rem 0;
    margin: 0;
    justify-content: center;
    text-align: center;
    border-bottom: 0.0625rem solid rgba(255, 255, 255, 0.1);
    display: block;
    overflow: visible;
    word-spacing: 0.25rem;
  }
}

.digestContent {
  width: 100%;
  max-width: 75rem;
  margin: 0 auto;
  padding: 2.5rem 1.25rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(18.75rem, 1fr));
  gap: 1.875rem;
  opacity: 0;
  animation: fadeIn 0.8s cubic-bezier(0.16, 1, 0.3, 1) forwards;
  animation-delay: 0.4s;

  @media screen and (max-width: $mobile) {
    padding: 0;
    gap: 0;
    grid-template-columns: 1fr;
  }
}

.digestItem {
  position: relative;
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);

  @media screen and (max-width: $mobile) {
    transition: none;
  }

  &:hover {
    transform: translateY(-0.3125rem);

    .digestPreview {
      background: rgba(255, 255, 255, 0.05);
      box-shadow: 0 0.625rem 1.875rem rgba(0, 0, 0, 0.2);

    }

    @media screen and (max-width: $mobile) {
      transform: none;

      .digestPreview {
        background: rgba(255, 255, 255, 0.03);
        box-shadow: none;
      }
    }
  }
}

.digestPreview {
  display: flex;
  flex-direction: column;
  gap: 0.9375rem;
  height: 18.75rem;
  padding: 1.5625rem;
  background: rgba(255, 255, 255, 0.03);
  border-radius: 0.75rem;
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);
  box-shadow: 0 0.3125rem 1.25rem rgba(0, 0, 0, 0.1);
  overflow: hidden;
  border: 0.0625rem solid rgba(255, 255, 255, 0.1);

  @media screen and (max-width: $mobile) {
    height: auto;
    padding: 0.75rem;
    gap: 0.25rem;
    border-radius: 0;
    box-shadow: none;
    background: none;
    border: none;
    border-bottom: 0.0625rem solid rgba(255, 255, 255, 0.1);
  }
}

.digestMeta {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding-bottom: 0.9375rem;
  border-bottom: 0.0625rem solid rgba(255, 255, 255, 0.1);

  @media screen and (max-width: $mobile) {
    padding-bottom: 0.25rem;
    gap: 0.125rem;
    border: none;
  }
}

.digestAuthor {
  font-family: 'Space Mono', monospace;
  font-size: 1.125rem;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 0.3125rem;

  @media screen and (max-width: $mobile) {
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 0;
  }
}

.digestTitle {
  font-family: 'Space Mono', monospace;
  font-size: 1rem;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.7);
  line-height: 1.4;

  @media screen and (max-width: $mobile) {
    font-size: 0.875rem;
    line-height: 1.2;
    color: rgba(255, 255, 255, 0.6);
  }
}

.digestContentText {
  font-family: 'Space Mono', monospace;
  font-size: 0.875rem;
  line-height: 1.6;
  color: rgba(255, 255, 255, 0.8);
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;

  @media screen and (max-width: $mobile) {
    display: none;
  }
}

.footer {
  flex-shrink: 0;
  padding-top: 1.875rem;
  display: flex;
  flex-direction: column;
  gap: 1.875rem;

  @media screen and (max-width: $mobileAlb) {
    gap: 0.9375rem;
  }

  .footerLink {
    font-family: "Roboto";
    font-size: 2rem;

    @media screen and (max-width: $tabletAlb) {
      font-size: 1.5rem;
    }

    @media screen and (max-width: $tablet) {
      font-size: 1rem;
    }

    @media screen and (max-width: $mobile) {
      font-size: 0.875rem;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  .footerDescription {
    font-family: "Roboto";
    font-size: 3rem;

    @media screen and (max-width: $tabletAlb) {
      font-size: 2.25rem;
    }

    @media screen and (max-width: $tablet) {
      font-size: 1.625rem;
    }

    @media screen and (max-width: $mobileAlb) {
      font-size: 1.0625rem;
    }

    @media screen and (max-width: $mobile) {
      font-size: 0.875rem;
    }

    a {
      &:hover {
        text-decoration: underline;
      }
    }
  }

  @media screen and (max-width: $mobile) {
    margin-top: 0.9375rem;
  }
}

.linebottom {
  background-color: #fff;
  width: 100vw;
  height: 0.125rem;
}

body {
  background-color: black;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(1.25rem);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}
