@import "../../styles/vars.scss";

.publication {
  width: 100%;
  max-width: 62.5rem; // 1000px
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 1.25rem; // 20px
  padding: 3.75rem; // 60px
  background: rgba(255, 255, 255, 0.03);
  border: 0.0625rem solid rgba(255, 255, 255, 0.1); // 1px
  border-radius: 0.25rem; // 4px
  box-shadow: 0 0.25rem 1.25rem rgba(0, 0, 0, 0.2); // 4px 20px
  opacity: 0;
  transform: translateY(1.25rem);
  animation: fadeInUp 0.8s cubic-bezier(0.16, 1, 0.3, 1) forwards;

  @media screen and (max-width: $tablet) {
    padding: 2.5rem; // 40px
    max-width: 56.25rem; // 900px
  }

  @media screen and (max-width: $mobile) {
    gap: 0.75rem; // 12px
    padding: 1.25rem; // 20px
  }

  .author {
    font-family: 'Space Mono', monospace;
    color: rgba(255, 255, 255, 0.9);
    font-size: 2rem; // 32px
    font-weight: 500;
    transition: color 0.3s ease;

    &:hover {
      color: white;
      text-decoration: none;
    }

    @media screen and (max-width: $mobile) {
      font-size: 1.5rem; // 24px
    }
  }

  .contentName {
    font-family: 'Space Mono', monospace;
    font-size: 1.5rem; // 24px
    font-weight: 500;
    color: rgba(255, 255, 255, 0.7);
    padding-bottom: 0.9375rem; // 15px
    border-bottom: 0.0625rem solid rgba(255, 255, 255, 0.1); // 1px

    @media screen and (max-width: $mobile) {
      font-size: 1rem; // 16px
      padding-bottom: 0.5rem; // 8px
    }
  }

  .content {
    font-family: 'Space Mono', monospace;
    font-size: 1.125rem; // 18px
    line-height: 1.8;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.8);
    letter-spacing: 0.0125em; // 0.2px
    max-width: 50rem; // 800px
    margin: 0 auto;

    p {
      margin: 1.5em 0;
    }

    a {
      color: rgba(255, 255, 255, 0.9);
      text-decoration: underline;
      transition: color 0.3s ease;

      &:hover {
        color: white;
      }
    }

    @media screen and (max-width: $tablet) {
      max-width: 43.75rem; // 700px
    }

    @media screen and (max-width: $mobile) {
      font-size: 0.9375rem; // 15px
      line-height: 1.6;
      letter-spacing: 0.00625em; // 0.1px
      max-width: 100%;
    }
  }
}

.center {
  text-align: center;
}

.publicationContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 3.75rem; // 60px

  @media screen and (max-width: $tablet) {
    gap: 2.5rem; // 40px
  }

  @media screen and (max-width: $mobile) {
    gap: 1.25rem; // 20px
  }

  .publication {
    @for $i from 1 through 10 {
      &:nth-child(#{$i}) {
        animation-delay: #{$i * 0.1}s;
      }
    }
  }
}

.sectionContents {
  width: 100%;
  max-width: 62.5rem; // 1000px
  margin: 0 auto 3.75rem; // 60px
  display: flex;
  flex-direction: column;
  gap: 1.25rem; // 20px
  padding: 3.75rem; // 60px
  background: rgba(255, 255, 255, 0.03);
  border: 0.0625rem solid rgba(255, 255, 255, 0.1); // 1px
  border-radius: 0.25rem; // 4px
  box-shadow: 0 0.25rem 1.25rem rgba(0, 0, 0, 0.2);

  @media screen and (max-width: $tablet) {
    margin-bottom: 2.5rem; // 40px
    padding: 2.5rem; // 40px
    max-width: 56.25rem; // 900px
  }

  @media screen and (max-width: $mobile) {
    margin-bottom: 1.25rem; // 20px
    padding: 1.25rem; // 20px
    gap: 0.9375rem; // 15px
  }

  .sectionContentsTitle {
    font-family: 'Space Mono', monospace;
    font-size: 1.75rem; // 28px
    font-weight: 500;
    color: rgba(255, 255, 255, 0.9);
    padding-bottom: 0.9375rem; // 15px
    border-bottom: 0.0625rem solid rgba(255, 255, 255, 0.1); // 1px

    @media screen and (max-width: $mobile) {
      font-size: 1.25rem; // 20px
      padding-bottom: 0.625rem; // 10px
    }
  }

  .sectionContentsItem {
    display: block;
    color: rgba(255, 255, 255, 0.8);
    padding: 0.9375rem; // 15px
    border-radius: 0.25rem; // 4px
    transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);
    text-decoration: none;
    transform: translateX(0);

    &:hover {
      background: rgba(255, 255, 255, 0.05);
      transform: translateX(0.625rem); // 10px
    }

    .sectionContentsAuthor {
      font-family: 'Space Mono', monospace;
      text-align: left;
      font-size: 1.125rem; // 18px
      font-weight: 400;

      @media screen and (max-width: $mobile) {
        font-size: 1rem; // 16px
      }
    }
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(1.25rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
