@import "../../styles/vars.scss";

.titleContainer {
  margin-bottom: 82px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-transform: uppercase;
  @media screen and (max-width: $tabletAlb) {
    margin-bottom: 36.5px;
  }
  @media screen and (max-width: $tablet) {
    gap: 10.5px;
  }

  @media screen and (max-width: $mobileAlb) {
    margin-bottom: 28.5px;
    gap: 4px;
  }

  @media screen and (max-width: $mobile) {
    margin-bottom: 10px;
  }
  .title {
    text-align: center;
    font-size: 200px;
    font-weight: 500;
    margin-top: -26px;

    @media screen and (max-width: $tabletAlb) {
      font-size: 160px;
      margin-top: -22.5px;
    }

    @media screen and (max-width: $tablet) {
      font-size: 110px;
      margin-top: -15.5px;
    }

    @media screen and (max-width: $mobileAlb) {
      font-size: 70px;
      margin-top: -9.5px;
    }

    @media screen and (max-width: $mobile) {
      font-size: 50px;
      margin-top: -7px;
    }
  }
  .subtitle {
    text-align: center;
    font-size: 128px;
    font-weight: 700;
    margin-top: -19px;
    @media screen and (max-width: $tabletAlb) {
      font-size: 96px;
      margin-top: -11px;
    }

    @media screen and (max-width: $tablet) {
      font-size: 64px;
    }

    @media screen and (max-width: $mobileAlb) {
      font-size: 48px;
    }

    @media screen and (max-width: $mobile) {
      font-size: 36px;
      margin-top: -2px;
    }
  }
}
.color {
  color: black;
}

.link {
  &:hover {
    text-decoration: underline;
  }
}
